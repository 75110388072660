.download-template-button {
  margin: 10px !important;
  background-color: var(--shared-colors-napa-gold-10, #ffc72c) !important;
  color: var(--shared-colors-napa-blue-10, #001489) !important;
  border-color: inherit !important;
  font-size: 1px !important;
}

.download-anchor {
  text-decoration: none;
  color: var(--shared-colors-napa-blue-10, #001489) !important;
}
