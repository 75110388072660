.container {
  background: radial-gradient(var(--napa-blue-700), var(--napa-blue-1000));
  display: grid;
  min-height: 100vh;
  padding: 1rem;
  place-items: center;
}
.section {
  max-width: 25rem;
  width: 100%;
}

.header {
  align-items: center;
  display: flex;
  flex-direction: column;
  padding-bottom: var(--rem-10);
}

.img {
  margin-bottom: var(--rem-03);
  width: 6rem;
}

.h1 {
  color: var(--napa-white);
  font-family: var(--headings-font-condensed);
  font-size: var(--rem-06);
  text-transform: uppercase;
}

.display_msg {
  color: #fff;
}
