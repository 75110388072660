.add-regulations-container {
  padding-top: 15px;
}

.add-regulation-form {
  border-radius: 33px;
  background: var(--shared-colors-napa-white, #fff);
  box-shadow: 0px 0px 2px 1px rgba(0, 0, 0, 0.25);
  width: 70%;
  padding: 25px;
  margin-top: 20px;
}

.stepper-label {
  color: var(--shared-colors-napa-blue-10, #001489) !important;
}

.inValidRow {
  background-color: red !important;
}

.stepper-content-title {
  color: var(--shared-colors-napa-blue-10, #001489);
  font-feature-settings:
    "clig" off,
    "liga" off;
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  font-size: 36px;
  font-style: normal;
  font-weight: 500;
  line-height: 44px;
}

.stepper-content-subTitle {
  color: var(--shared-colors-napa-blue-10, #001489);
  font-feature-settings:
    "clig" off,
    "liga" off;
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: 0.4px;
}

.stepper-form-label {
  color: var(--shared-colors-napa-gray-10, #333);
  font-feature-settings:
    "clig" off,
    "liga" off;
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: 28px;
  letter-spacing: 0.52px;
}

.stepper-actions {
  display: flex;
  margin-top: 12px;
}

.back-btn {
  background-color: var(--shared-colors-napa-white, #ffffff) !important;
  color: var(--shared-colors-napa-blue-10, #001489) !important;
  border-color: inherit !important;
}

.continue-btn {
  background-color: var(--shared-colors-napa-gold-10, #ffc72c) !important;
  color: var(--shared-colors-napa-blue-10, #001489) !important;
  border-color: inherit !important;
}

.alertContainer {
  margin: 20px 0;
}

.apiErrorAlert {
  font-size: 16px;
  font-weight: bold;
}

.uploaded-parts-table {
  margin: 15px 0;
}

.regulated-parts-section {
  margin: 25px 0;
}

.MuiFormLabel-colorPrimary {
  color: var(--shared-colors-napa-gray-10, #333) !important;
  font-weight: 700 !important;
}
